
















































import {Component, Prop, Vue} from "vue-property-decorator";
import FileUpload from "@/components/common/FileUpload.vue";
import PersonDTO from "@/dto/person/PersonDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CompanyService from "@/services/CompanyService";
import {namespace} from "vuex-class";

const AppModule = namespace("App");

@Component({
  components: {FileUpload}
})
export default class UploadFormWithCompanySelectModal extends Vue {

  @AppModule.State
  private loading!: boolean;

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  private onFilesUploaded!: (files: FileMetaDTO[], employerId: number, period: string) => void

  @Prop()
  private employee!: PersonDTO;

  private companies: Array<CompanyDTO> = [];

  private employer: CompanyDTO | null = null;

  private period = "";

  private isPeriodEntered = false;

  mounted(){
    this.loadCompanies();
  }

  loadCompanies(){
    this.startLoading();
    return CompanyService.getAll().then(
        response => {
          this.companies = response.data;
        },
        error => {
          //todo
        }
    ).then(() => this.stopLoading());
  }

  onUploaded(files: FileMetaDTO[]) {
    this.onFilesUploaded(files, this.employer?.id as number, this.period);
    this.$modal.hideAll();
  }

  setEmployer(company: CompanyDTO){
    this.employer = company;
  }

}
