class PayrollFileCategories {
    public PAYSTUB = 'PAYSTUB';
    public F_W2 = 'F_W2';
    public F_1099 = 'F_1099';
    public FEDERAL_REPORT = 'FEDERAL_REPORT';
    public REGIONAL_REPORT = 'REGIONAL_REPORT';
    public DETAILS = 'DETAILS';
    public GENERAL_DETAILS = "GENERAL_DETAILS"
}

export default new PayrollFileCategories();