import { render, staticRenderFns } from "./UploadFormWithCompanySelectModal.vue?vue&type=template&id=7477fa81&scoped=true&"
import script from "./UploadFormWithCompanySelectModal.vue?vue&type=script&lang=ts&"
export * from "./UploadFormWithCompanySelectModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7477fa81",
  null
  
)

export default component.exports