import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class SavePayrollFileRequest {

    private personId: number | null;

    private companyId!: number;

    private period!: string;

    private file!: FileMetaDTO;

    private category!: string;

    constructor(personId: number | null, companyId: number, period: string, file: FileMetaDTO, category: string) {
        this.personId = personId;
        this.companyId = companyId;
        this.period = period;
        this.file = file;
        this.category = category;
    }

}